/**
 * External dependencies
 */
 import React from "react"

 /**
  * Internal dependencies
  */
 
 import Layout from "@components/layout"
 import Seo from "@components/seo"
 import { Col, Container, Row } from "react-bootstrap";
 import PropertyInfo from "@components/property-info/property-info"
 import PropertySlider from "@components/property-slider/property-slider"
 import { currencyFormat } from "@components/common/utils";
 import PropertyActions from "@components/property-actions/property-actions"
 import Breadcrumbs from "@components/breadcrumbs/breadcrumbs"
 import { useQuery } from '@apollo/client';
 import logoBlack from "../images/svg/logo.svg"
import NotFoundPage from "../pages/404"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { navigate } from "@reach/router"
import gql from "graphql-tag"
import SimilarProperties from "../components/home-properties/similar-properties";
import LetSimilarProperties from "../components/home-properties/let-similar-properties";
import PropertyAreaGuide from "../components/PropertyAreaGuide/PropertyAreaGuide";

 const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){  
    properties(where:{id: $id, publish:true}) {
      id
      title
      introtext
      office_crm_id
      display_address
      price_qualifier
      available_from
      price
      images
      imagetransforms
      accomadation_summary
      description
      long_description
      epc
      latitude
      longitude 
      search_type
      address
      bedroom
      bathroom
      building  
      floorplan
      reception
      virtual_tour
      brochure
      floorarea_min
      floorarea_type
      crm_negotiator_id
      status
    }
  }
`;
 const PropertyDetails = (props) => {
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    // debugger

    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, { variables: { id: id },fetchPolicy: "no-cache" });

    

    var properties = null;
    if (property_details?.properties?.length > 0) {
        properties = property_details?.properties[0];
    }
    // console.log(properties)
    // if (properties === null) {
    //     navigate('/property/for-sale/')
    // }
    var action = "for sale";
    if (properties?.search_type == "lettings") {
        action = "to rent";
    }

    var metaTitle = `${properties?.bedroom} bedroom ${properties?.building} ${action} in ${properties?.display_address}`
    var metaDesc = `Know the details of ${properties?.bedroom} bedroom ${properties?.building} ${action} in ${properties?.display_address} with Strakers at ${currencyFormat(properties?.price, '£', false)}. Book a viewing to find a ${properties?.building} for you.`    
    var searchData = {
        action: action,
        areas: (properties?.address?.town)? properties?.address?.town : properties?.address?.area,
        bedrooms: null,
        defaultArea: (properties?.address?.town)? properties?.address?.town : properties?.address?.area,
        maxPrice: null,
        minPrice: null,
        propertyType: null,
        search_type: properties?.search_type
    }

    if (loading)
  return (
    <div className="static-news gql-loading-screen">
      <Container>
        <div className="gql-loading">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </Container>
    </div>
  )
  
  return (
        property_details?.properties?.length > 0 ? 
   <Layout searchtype={properties?.search_type} propertyClass="property-details-template">
     <Seo 
     title={metaTitle}
    location={props.location}
    description={metaDesc}
    image={properties?.images[0]?.url ? properties?.images[0].url : null} />
     <Breadcrumbs data={properties} Page={`${properties?.display_address} ${(properties?.address?.town)? (properties?.address?.town === undefined ? "" : ", "+ properties?.address?.town) : (properties?.address?.area === undefined ? "" : ", "+properties?.address?.area)}`}  alias={properties?.search_type} type={"property-details-page"} social={true} />
     <PropertySlider data={properties} />
     <PropertyInfo data={properties} />
     <PropertyActions data={properties} />
     {properties?.search_type === "sales" ?
     <SimilarProperties data={properties} />
     :
     <LetSimilarProperties data={properties} />
  }
     <PropertyAreaGuide data={properties} />
   </Layout>
 
 : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
  )
    
}
 
 export default PropertyDetails 